import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../../../components/LayoutFund"
import { theme } from "../../../components/Theme"

/** static sections */
import FundLeadershipStacySection from "../../../components/static-sections/FundLeadershipStacySection"

/** dynamic sections */
import HeroInvestImage from "../../../components/dynamic-sections/HeroInvestImage"
import HeroLeadershipText from "../../../components/dynamic-sections/HeroLeadershipText"
import HeroVideoFund from "../../../components/dynamic-sections/HeroVideoFund"

/** svg */
import NorhartBlackCircleIcon from "../../../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.invest

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const stacyFundLeadership: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/fund/leadership/stacy-ekholm",
          name: "Stacy Ekholm | Norhart Fund",
          image: `${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Stacy Ekholm | Norhart Fund"
      description=""
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroInvestImage
        items={[
          {
            image: "/fund/leadership/stacy-hero.png",
          },
        ]}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroLeadershipText
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="EXECUTIVE LEADERSHIP"
        title="Stacy Ekholm, Chief Operating Officer"
        formatSubTitle={() => (
          <>
            Stacy Ekholm serves as the Chief Operating Officer at Norhart Inc. She is responsible for overseeing
            Norhart's managed portfolio and coordinating the efforts of various departments to drive operational
            excellence.
            <br />
            <br />
            With over 20 years of specialized experience in property management, real estate, and business development,
            Stacy has held senior positions at several Minneapolis property management firms. Prior to joining Norhart,
            she was the Senior Portfolio Director at StuartCo, where she managed 2,000 multifamily units and negotiated
            strategic partnerships with institutional owners.
            <br />
            <br />
            As the COO, Stacy manages over 1,200 Norhart multifamily units on a day-to-day basis. She advises on new
            builds, utilizing her extensive industry experience to areas such as site selection, market analysis,
            architecture, and design.
            <br />
            <br />
            Stacy is a Certified Property Manager (CPM), Certified Occupancy Specialist (COS), Certified Residential
            Manager (CRM), and Certified Manager of Community Associations (CMCA). She has been awarded three times by
            Minnesota's Multifamily Housing Association (MHA), including the top Multi-Site Manager of the Year award.
            Stacy is highly regarded in Minnesota's property management community and regularly mentors young
            professionals in real estate. She also leads seminars on multifamily budgeting and marketing strategies at
            the MHA.
            <br />
            <br />
            Stacy resides in Cottage Grove, Minnesota, with her husband and two daughters, one of whom works in property
            management and development. She is proud to have organized many of Norhart's community initiatives,
            including food drives and planning and strategizing home repairs for low-income veterans.
          </>
        )}
        subNote=""
        image=""
        imageAlt=""
        textColor="#111111"
        asoEffect="fade-in"
      />

      <FundLeadershipStacySection colorPalette={colorPalette} />
    </Layout>
  )
}

/** export */
export default stacyFundLeadership
